.ImageButton {
    max-width: 65px;
    max-height: 65px;
}

.ImageButton button {
    width: 65px;
    height: 65px;
    background-color: white;
    border: 2px solid black;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ImageButton button:hover {
    background-color: gainsboro;
}

.ImageButton button:active {
    background-color: silver;
}

.ImageButton img {
    width: 50px;
    height: 50px;
}