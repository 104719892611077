.InputItem {
    display: flex;
    flex-direction: row;
}

.InputItem input {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 16px;
    font-family: "Nanum Gothic";
    font-weight: bold;
}

.InputItem p {
    padding-left: 5px;
    font-weight: bold;
}