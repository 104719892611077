.TimeCounter {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 70px;
}

.TimeCounter p {
    padding: 10px;
    font-size: 54px;
    font-weight: bold;
    color: white;
}