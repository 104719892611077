.Home {
    display: flex;
    flex-direction: column;
}

.Home .ad_section {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Home .KakaoAd {
    max-width: 100%
}

.Home .top_section {
    display: flex;
}

.Home .bottom_section {
    display: flex;
    flex-direction: row;
}