.Button button {
    min-width: 65px;
    min-height: 65px;
    border-radius: 15px;
    border: 2px solid black;
    background-color: white;
    font-family: "Nanum Gothic";
    font-weight: bold;
    white-space: pre-wrap;
}

.Button button:hover {
    background-color: gainsboro;
}

.Button button:active {
    background-color: silver;
}